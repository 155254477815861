<template>
  <b-tabs
    v-if="selected_entity2"
    id="menu"
    class="position-relative pb-5"
  >
    <hr class="mt-1" :class="'hr-valid-bar hr'+selected_entity2.context.details.validity">

    <b-tab active title="Entity" class="mx-1">
      <EntityTab @clicked="updateGraph" />
    </b-tab>

    <b-tab title="Requirements" class="mx-1">
      <RequirementsTab />
    </b-tab>

    <b-tab title="Properties" class="mx-1">
      <PropertiesTab />
    </b-tab>

    <b-tab title="Context" class="mx-1">
      <ContextTab @clicked="updateGraph" />
    </b-tab>

    <b-tab v-if="auth.roles && auth.roles.includes('administrator')" title="Debug">
      <template #title>
        <feather-icon icon="CodeIcon" class="text-danger" />
        <span class="text-danger">Debug</span>
      </template>

      <div class="mx-75">
        <h6 class="text-danger">
          Component Details
        </h6>
        <pre>{{ selected_entity2.context.details }}</pre>

        <h6 class="text-danger">
          Component Full Context
        </h6>
        <pre>{{ selected_entity2 }}</pre>
      </div>
    </b-tab>
  </b-tabs>
  <div v-else class="text-center mt-4">
    <b-spinner />
    <b-card>
      <span class="">Loading...</span>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EntityTab from './EditMenuComponents/Entity.vue'
import PropertiesTab from './EditMenuComponents/Properties.vue'
import ContextTab from './EditMenuComponents/Context.vue'
import RequirementsTab from './EditMenuComponents/Requirements.vue'

export default {
  components: {
    EntityTab,
    PropertiesTab,
    ContextTab,
    RequirementsTab,
  },
  data: () => ({
    context: {},
  }),
  computed: {
    ...mapState({
      selected_entity2: state => state.domainModel.selected_entity2,
      auth: state => state.auth,
    }),
  },
  methods: {
    updateGraph() {
      this.$emit('clicked')
    },
  },

}
</script>

<style scoped>
.hr-valid-bar {
  width: 100%;
  height: 10px;
  margin: -2rem auto 1rem;
  border-radius: 5px;
}

.hrValid {
  background-color: #37c74f;
}

.hrImplied {
  background-color: #f38717;
}

.hrInvalid {
  background-color: #ef4143;
}
</style>
