<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col class="ml-1">
            <b-button
              variant="outline-secondary"
              size="sm"
              class="float-right"
              @click="goHome"
            >
              Reset
            </b-button>
            <label style="margin-right: 0.3rem; font-size: 0.857rem; color: #d0d2d6;">
              Tree
            </label>
            <b-form-checkbox
              checked="true"
              name="check-button"
              switch
              inline
              @change="toggleForce"
            >
              Network
            </b-form-checkbox>
            <!--              <b-button-->
            <!--                @click="-->
            <!--                  clearSVG();-->
            <!--                  sortTree();-->
            <!--                  generateGraph();-->
            <!--                "-->
            <!--              >Clear</b-button>-->
            <!-- <b-button @click="sortTree()"> Sort </b-button> -->
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div v-if="isForce" id="d3ForceGraph" class="ml-1 mt-1 border" />
            <ecosystem-view v-else :key="graphKey" @loaded="toggleForce(); refreshGraph()" />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="4" class="overflow-auto entity-pane">
        <EntityDetails :key="entityKey" @clicked="refreshGraph()" />
      </b-col>
    </b-row>
    <!-- <b-row class="justify-content-md-center">
      <b-col>
        <div>Nodes: {{ this.composition_tree.nodes.length }}</div>
        <div>Edges: {{ this.composition_tree.edges.length }}</div>
        <div class="mb-5">{{ this.comps }}</div>
        <div style="width: 100%; height: 500px; overflow-x: scroll;">
          <p>
            {{ "safeJSON(this.links)" }}
          </p>
        </div>
        <div style="margin-bottom: 300px"></div>
      </b-col>
    </b-row> -->

    <!-- Modals -->
    <AddEntityModal @added="refreshGraph()" />
    <DeleteEntityModal @deleted="refreshGraph()" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as d3 from 'd3'

import { BFormCheckbox } from 'bootstrap-vue'
import EcosystemView from '@/components/Domain/EcosystemView.vue'
import EntityDetails from '@/components/Domain/EntityDetails.vue'
import AddEntityModal from '@/components/Domain/Modals/AddEntity.vue'
import DeleteEntityModal from '@/components/Domain/Modals/DeleteEntity.vue'

export default {
  name: 'Ecosystem',
  components: {
    EntityDetails,
    BFormCheckbox,
    EcosystemView,
    AddEntityModal,
    DeleteEntityModal,
  },
  data() {
    return {
      // SVG stuff
      svgX: 1600,
      svgY: 1050,
      // Comps stuff
      comps: null,
      links: null,
      // D3 force graph on/off (Default: true)
      isForce: true,
      wWidth: window.innerWidth,
      // Old D3 graph update stuff
      graphKey: 0,
      entityKey: 0,
    }
  },

  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
  },

  created() {
    if (!this.$route.query.force) {
      const curForce = this.isForce ? 'true' : 'false'
      this.$router.push({ query: { force: curForce } })
    } else this.isForce = this.$route.query.force === 'true'
    console.debug('Current setup: ', (this.isForce ? 'Force TRUE' : 'Force FALSE'))
  },

  mounted() {
    if (this.isForce) {
      const curRoute = this.$route.query.id
      console.debug('Current route should be: ', curRoute)
      this.$store.dispatch('domainModel/getCompTreeData', curRoute || null).then(() => {
        setTimeout(() => {
          console.debug('domainModel/compTreeData on generate: ', this.composition_tree)
          this.clearSVG()
          this.sortTree()
          this.generateGraph()
        }, 500)
        console.debug('Ran generate command.')
      })
    }
  },

  methods: {
    // Sort the API data in a format that D3 can bind/use
    // When D3 is using a force graph, the XY coords are irrelevant
    // When force is turned off, this should sort the nodes into a static layout
    sortTree() {
      // Clear the arrays first
      const vueApp = this // Avoids issues with 'this' not working inside nested functions
      vueApp.comps = {}
      vueApp.links = []

      // Sort the nodes and assign XY coordinates
      const { nodes } = vueApp.composition_tree
      const { edges } = vueApp.composition_tree
      const root = nodes.find(obj => obj.id === vueApp.composition_tree.root)
      console.warn('Root is: ', root)
      console.warn('Comp data is: ', vueApp.composition_tree)

      // Draw children of the node (point) if it has any
      function findChildren(point) {
        // Check if point has children
        if (edges.find(obj => obj.source === point.id)) {
          // Store children by ID in a list
          const pointChildren = []

          for (let i = 0; i < edges.length; i += 1) {
            if (edges[i].source === point.id) {
              const childID = edges[i].target
              const childName = nodes.find(obj => obj.id === childID).qualified_name
              const childAbstract = nodes.find(obj => obj.id === childID).abstract === true
              pointChildren.push({
                id: childID,
                name: childName,
                abstract: childAbstract,
              })
            }
          }

          // Draw children

          for (let i = 0; i < pointChildren.length; i += 1) {
            /*
            // Determine minimum node separation distance (radius) based on node type
            let radius, angle, randX, randY;

            if (point.class === "root") {
              // Root's children should always be "abstract"
              // Abstract nodes (orange) will be layed out randomly on a circumference of a circle with a `radius`
              radius = 360;

              angle = Math.random() * Math.PI * 2;
              //angle = ((360 / pointChildren.length) * i) * (Math.PI/180);
            } else if (point.class === "abstract") {
              // Abstract's children will most likely be "node"
              // Regular nodes (yellow) should be layed out uniformly and *not* randomly due to high quantity of nodes
              radius = 240;

              angle = (360 / pointChildren.length) * i * (Math.PI / 180);
            } else {
              // Node's children will most likely be "node"
              // Uniform layout for better physics simulation
              radius = 90;

              angle = (360 / pointChildren.length) * i * (Math.PI / 180);
            }

            randX = point.x + Math.cos(angle) * radius;
            randY = point.y + Math.sin(angle) * radius;

           // Total range of the X position for child nodes depending on their amount
           let setDistance = pointChildren.length * 100;
           let posY = point.y + (50 + (7.5 * i));
           let childX = point.x - (setDistance/2)
           //let posX = childX + ((setDistance / pointChildren.length) * (i));
           let posX = childX + (50 * i);

           console.debug("Set Distance: ", setDistance);
           console.debug("X position: ", posX);
           console.debug("Y position: ", posY);
           */

            const newNode = {
              // x: randX,
              // y: randY,
              x: 0,
              y: 0,
              id: pointChildren[i].id,
              name: pointChildren[i].name,
              class: pointChildren[i].abstract ? 'abstract' : 'node',
              children: [],
            }
            // vueApp.comps.push(newNode);
            point.children.push(newNode)

            findChildren(newNode)
          }
        } else {
          delete point.children
        }
      }

      function drawChildren(point) {
        console.debug('!!!!!!!!!!!!!!!!!!!!!!')
        // console.debug(root);
        console.debug('Current point: ', point.name)
        console.debug('Current point coordinates: ', point.x, point.y)
        if (point.children) console.debug('Current point children amount: ', point.children.length)
        console.debug('!!!!!!!!!!!!!!!!!!!!!!')
        // If point is root, set its X pos to half the width of the SVG
        if (point.id === root.id) {
          point.x = vueApp.svgX / 2
        }

        if (point.children) {
          const pChildren = point.children
          let setDistance = 0
          for (let i = 0; i < pChildren.length; i += 1) {
            if (pChildren[i].children) {
              setDistance += pChildren[i].children.length * 50
            } else {
              setDistance += 50
            }
          }

          console.debug('Buffer size for row: ', setDistance)

          const posY = point.y + 50
          let posX = point.x - (setDistance / 2)

          for (let i = 0; i < pChildren.length; i += 1) {
            if (i > 0) {
              if (pChildren[i - 1].children) {
                posX += pChildren[i - 1].children.length * 50
              }
            } else {
              posX = point.x - (setDistance / 2)
            }
            if (pChildren[i].children) {
              posX += (pChildren[i].children.length * 50)
            } else {
              posX += 50
            }

            pChildren[i].x = posX
            pChildren[i].y = posY

            drawChildren(pChildren[i])
          }
        }
      }

      // Draw root first
      vueApp.comps = {
        x: 0, // vueApp.svgX / 2,
        y: 0, // vueApp.svgY / 2,
        id: root.id,
        name: root.qualified_name,
        class: 'root',
        children: [],
      }

      findChildren(vueApp.comps)
      // drawChildren(vueApp.comps)

      // Sort the links/edges using the nodes array
      /*
      for (let i = 0; i < edges.length; i += 1) {
        let src = vueApp.comps.findIndex((obj) => obj.id === edges[i].source);
        let trgt = vueApp.comps.findIndex((obj) => obj.id === edges[i].target);

        vueApp.links.push({
          source: src,
          target: trgt,
        });
      }
      */
    },

    generateGraph() {
      const vueApp = this // Avoid issues with 'this' not working inside nested functions

      // SVG dimensions
      const w = this.svgX
      const h = this.svgY

      // Node circle attributes
      const cx = w / 2
      const cy = h / 2
      const r = 15

      const root = d3.hierarchy(vueApp.comps)
      // const root = vueApp.comps;

      const simulation = d3
        .forceSimulation()
        .force('charge', d3.forceManyBody().strength(-100))
        .force('center', d3.forceCenter(w / 2, h / 2))
        .force(
          'collision',
          d3.forceCollide().radius(d => 30),
        )
        .on('tick', tick)

      simulation
        .force(
          'link',
          d3
            .forceLink()
            .id(d => d.num)
            .distance(100)
            .strength(1.2),
        )

      const svg = d3
        .select('#d3ForceGraph')
        .append('svg')
        .attr('viewBox', [0, 0, w, h])
        .attr('id', 'svgBox')
      // .attr("width", w)
      // .attr("height", h)

      const bground = svg.append('rect')

      bground
        .attr('width', w)
        .attr('height', h)
        .attr('class', 'd3bground')

      const dragHandler = d3
        .drag()
        .on('start', dragstart)
        .on('drag', dragged)
        .on('end', dragend)

      let g; let link; let gAll; let circle; let
        text

      function update() {
        // Clear the SVG contents first to get rid of previous nodes
        vueApp.clearNodes()

        const nodes = flatten(root)
        vueApp.links = root.links()
        const { links } = vueApp

        console.debug('LINK STUFF')
        console.debug(links)
        console.debug(vueApp.links)

        // Background contextmenu
        const bgMenuItems = [
          {
            title: 'Update',
            action: d => {
              // TODO: Action 3
              console.debug('Background Update action fired: ', d)
              update()
            },
          },
        ]

        bground.on('contextmenu', d => {
          // Prevents default right click behaviour of the browser
          d3.event.preventDefault()
          contextMenu(d, bgMenuItems, w, h, '#svgBox', 'bground', d3.mouse(d3.event.currentTarget))
        })

        gAll = svg.append('g').attr('id', 'everything')

        link = gAll
          .append('g')
          .attr('id', 'links')
          .selectAll('.link')
          .data(links, d => d.target.num)

        link.exit().remove()

        const linkEnter = link
          .enter()
          .append('line')
          .attr('class', 'link')
        // Assigns an ID using `l` for link + source and target `num` separated by "-"
          .attr('id', d => `l${d.source.num}-${d.target.num}`)
          .attr('class', 'd3ForceLink')

        link = linkEnter.merge(link)
        // console.debug("LINKS ORIG: ", links);
        // console.debug("LINKS DATA: ", link);
        console.debug('LINKS DOM: ', d3.select('#links').selectAll('line').data())

        g = gAll
          .append('g')
          .attr('id', 'nodes')
          .selectAll('.node')
          .data(nodes, d => d.num)

        g.exit().remove()

        // Menu items and their actions
        const nodeMenuItems = [
          {
            // route: { name: 'model_tests_filter', params: { filter: 'passed' }
            title: 'Zoom to this entity',
            action: d => {
              select(d)
              vueApp.$router.push({
                name: 'domain_ecosystem_focus',
                params: { id: d.data.id, force: 'true' },
              })
              vueApp.refreshGraph(d.data.id)
              // vueApp.$router.go();
            },
          },
          {
            title: 'Collapse',
            action: d => {
              // TODO: Action 1
              collapse(d)
              console.debug('Node Collapse action fired: ', d)
            },
          },
          {
            title: 'Select',
            action: d => {
              // TODO: Action 2
              select(d)
              console.debug('Node Select action fired: ', d)
            },
          },
          {
            title: 'Update',
            action: d => {
              // TODO: Action 3
              update()
              console.debug('Node Update action fired: ', d)
            },
          },
          {
            title: 'Add child',
            action: d => {
              vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
                .then(() => {
                  vueApp.$bvModal.show('add-entity-modal')
                })
            },
          },
          {
            title: 'Delete node',
            action: d => {
              vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
                .then(() => {
                  vueApp.$bvModal.show('delete_entity')
                })
            },
          },
        ]

        const nodeEnter = g
          .enter()
          .append('g')
          .attr('id', d => d.num)
          // .merge(g)
          // Click event handler
          .on('click', select)
          .on('contextmenu', d => {
            // Prevents default right click behaviour of the browser
            d3.event.preventDefault()
            contextMenu(d, nodeMenuItems, w, h, '#everything', 'node')
          })
          // .on("click", function(d) { return console.debug("HERE --------------", d); })
          .call(dragHandler)

        circle = nodeEnter
          .append('circle')
          // .attr("cx", function (d) { return d.data.x; })
          // .attr("cy", function (d) { return d.data.y; })
          .attr('r', d => {
            const nClass = d.data.class
            if (nClass === 'root') {
              return 15
            } if (nClass === 'abstract') {
              return 10
            }
            return 5
          })
          .attr('class', d => d.data.class)

        text = nodeEnter
          .append('text')
          // .attr("x", function (d) { return d.x; })
          // .attr("y", function (d) { return d.y; })
          .attr('text-anchor', 'middle')
          .attr('font-size', d => {
            const nClass = d.data.class
            if (nClass === 'root') {
              return 16
            } if (nClass === 'abstract') {
              return 10
            }
            return 8
          })
          .text(d => {
            if (d.data.name) return d.data.name
            return 'N/A'
          })
          .attr('class', 'd3ForceText')

        g = nodeEnter.merge(g)

        // Initialise force
        simulation.nodes(nodes)
        simulation.force('link').links(links)

        // Circle hover
        vueApp.circleHover(g)

        // Zoom actions
        const zoomHandler = d3
          .zoom()
          .scaleExtent([0.5, 5])
          .on('zoom', zoomActions)

        zoomHandler(svg)
        // Needs to be fired once after update to set the XY coordinates for the nodes correctly
        tick()
      }

      function zoomActions() {
        gAll.attr('transform', d3.event.transform)
      }

      function tick() {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y)

        g.attr('transform', d => `translate(${d.x}, ${d.y})`)

        g.select('text').attr('transform', d => {
          const nClass = d.data.class
          if (nClass === 'root') {
            return `translate(0, ${-20})`
          } if (nClass === 'abstract') {
            return `translate(0, ${-15})`
          }
          return `translate(0, ${-10})`
        })
        /* // Old on tick

        // Circle force
        circle.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

        // Text force
        text
          .attr("x", (d) => d.x)
          .attr("y", function (d) {
            if (d.class === "root") {
              return d.y - 20;
            } else if (d.class === "abstract") {
              return d.y - 15;
            } else {
              return d.y - 10;
            }
          });
          */
      }

      // ---- Drag functions

      // Drag functions have two methods - one to apply force to a node and "push/pull" it around, the other is to snap the node to cursor location
      // Depending if the graph force is enabled via `isForce` var, different method will be used
      let deltaX; let
        deltaY
      function dragstart(d) {
        // To prevent errors, all simulation commands must not fire when `isForce` is false
        d3.select(this).classed('dragging', true)
        if (!d.active) simulation.alphaTarget(0.3).restart()
        d3.event.subject.fx = d3.event.subject.x
        d3.event.subject.fy = d3.event.subject.y

        // Turn off node and link hover style when dragging
        d3.select('#nodes')
          .selectAll('g')
          .filter(() => !this.classList.contains('collapsed'))
          .transition()
          .duration(200)
          .style('opacity', 1)

        d3.select('#links')
          .selectAll('line')
          .transition()
          .duration(200)
          .style('opacity', 1)
          .style('stroke', 'gray')
          .style('stroke-width', 0.75)
      }

      function dragged(d) {
        // Simulation dependent variables
        d3.event.subject.fx = d3.event.x
        d3.event.subject.fy = d3.event.y
      }

      function dragend(d) {
        d3.select(this).classed('dragging', false)
        if (!d.active) simulation.alphaTarget(0)
        d3.event.subject.fx = null
        d3.event.subject.fy = null

        /*
        d3.select("#nodes")
          .selectAll("g")
          .filter(function () {
            return !this.classList.contains("current");
          })
          .transition()
          .duration(200)
          .style("opacity", 0.25);

        d3.select("#links").selectAll("line").transition().duration(200).style("opacity", 0.25);
        */
      }

      // Click on nodes handler
      function select(d) {
        // console.debug("SENDING: ", d.data.id);

        vueApp.$store.dispatch('domainModel/selectEntity2', d.data.id)
          .then(() => {
            vueApp.entityKey += 1
            // console.debug("SELECTED: ", d.data.id, "D3 NODE: ", d.data, "API RESPONSE: ", vueApp.selected_entity2.context.details)
          })
      }
      function collapse(d) {
        if (!d3.event.defaultPrevented) {
          if (d.children) {
            d._children = d.children
            d.children = null
          } else {
            d.children = d._children
            d._children = null
          }
          update()
        }
      }

      // Returns all nodes as a list
      function flatten(root) {
        const nodes = []
        let n = 0
        console.debug('ORIGINAL: ', vueApp.comps)
        console.debug('PARENT: ', root)
        function recurse(node) {
          // Check if it has children or not
          if (node.children) {
            node.children.forEach(recurse)
            console.debug('HAS CHILDREN: ', node)
          } else {
            console.debug('NO CHILDREN: ', node)
          }

          if (!node.num) node.num = `n${++n}`
          else ++n
          nodes.push(node)
        }
        recurse(root)
        return nodes
      }

      // Context menu constructor
      function menuFactory(x, y, menuItems, data, svgId) {
        console.debug('Menu coordinates: ', x, y)
        d3.select('.contextMenu').remove()

        // Create the menu
        d3.select(svgId)
          .append('g').attr('class', 'contextMenu')
          .selectAll('tmp')
          .data(menuItems)
          .enter()
          .append('g')
          .attr('class', 'menuEntry')
          .style({ cursor: 'pointer' })

        // Menu entries
        d3.selectAll('.menuEntry')
          .append('rect')
          .attr('x', x)
          .attr('y', (d, i) => y + (i * 30))
          .attr('rx', 2)
          .attr('width', 150)
          .attr('height', 30)
          .on('click', d => { d.action(data) })

        d3.selectAll('.menuEntry')
          .append('text')
          .text(d => d.title)
          .attr('x', x)
          .attr('y', (d, i) => y + (i * 30))
          .attr('dy', 20)
          .attr('dx', 75)
          .attr('text-anchor', 'middle')
          .on('click', d => { d.action(data) })

        // Cancel when click out
        d3.select('body')
          .on('click', () => {
            d3.select('.contextMenu').remove()
          })
      }

      // Context menu function call
      function contextMenu(d, menuItems, menuWidth, menuHeight, svgId, type, coords) {
        // For node
        if (type === 'node') menuFactory(d.x, d.y, menuItems, d, svgId)
        // For background
        else if (type === 'bground') menuFactory(coords[0], coords[1], menuItems, d, svgId) // console.debug("Coords: ", coords); console.debug("Menu coordinates: ", coords[0], coords[1]);
        d3.event.preventDefault()
        console.warn('CONTEXT MENU FIRED', d)
      }

      // Run the graph
      update()
    },

    // Animation handler for all circle elements
    // Needs to be called everytime a new element is created
    circleHover(g) {
      // Get text attributes
      const textSize = g.select('text').attr('font-size')

      // Hover on whole group
      // eslint-disable-next-line func-names
      g.on('mouseenter', function () {
        // Raise the selection to top visible layer
        d3.select(this).raise()

        // Set current node
        d3.select(this).classed('current', true)

        // Circle animation
        d3.select(this)
          .select('circle')
          .transition()
          .duration(200)
          .attr('stroke', 'wheat')
          .attr('stroke-width', 3)

        // Text animation
        d3.select(this)
          .select('text')
          .transition()
          .duration(200)
          .attr('font-size', d => {
            const nClass = d.data.class
            if (nClass === 'root') {
              return 20
            }
            return 18
          })

        // Other nodes animation/opacity
        // Should only fire when not dragging, as dragging has its own opacity animation
        if (!this.classList.contains('dragging')) {
          const lines = d3.select('#links').selectAll('line').data()
          let currNode = d3.select(this).datum()

          if (currNode.children) {
            const { children } = currNode
            // console.debug("CHILDREN: ", children);

            for (let i = 0; i < children.length; i += 1) {
              // Highlight all children nodes
              d3.select(`#${children[i].num}`)
                .classed('curChild', true)
              // Highlight all children node links
              d3.select(`#l${currNode.num}-${children[i].num}`)
                .classed('curChild', true)
                .style('stroke', 'orange')
                .style('stroke-width', 1)
            }
          }

          // Critical path and parent highlighting
          // Will always run until it hits the root
          do {
            for (let i = 0; i < lines.length; i += 1) {
              if (lines[i].target === currNode) {
                // Highlight all parents on the critical path
                d3.select(`#${lines[i].source.num}`)
                  .classed('curChild', true) // curChild despite being a parent

                // Critical path highlighting
                d3.select(
                  `#l${lines[i].source.num}-${lines[i].target.num}`,
                )
                  .classed('current', true) // current class despite being a line
                  .style('stroke', 'orangered')
                  .style('stroke-width', 1)

                currNode = lines[i].source
                break
              }
            }
            // console.debug("CURR CLASS: ", currNode.data.class);
          } while (currNode.data.class !== 'root')

          // Set all other lines and nodes (that aren't classed) to be 25% opacity
          // Both have an animation cycle of ~200ms which should be enough for visual
          d3.select('#nodes')
            .selectAll('g')
            .transition()
            .duration(200)
            .style('opacity', 0.25)

          d3.select('#links')
            .selectAll('line')
            .transition()
            .duration(200)
            .style('opacity', 0.25)

          d3.selectAll('.current')
            .transition()
            .duration(200)
            .style('opacity', 1)

          d3.selectAll('.curChild')
            .transition()
            .duration(200)
            .style('opacity', 0.75)
        }
        // eslint-disable-next-line func-names
      }).on('mouseout', function () {
        // Circle animation
        d3.select(this)
          .select('circle')
          .transition()
          .duration(200)
          .attr('stroke', null)
          .attr('stroke-width', null)

        // Text animation
        d3.select(this)
          .select('text')
          .transition()
          .duration(200)
          .attr('font-size', d => {
            const nClass = d.data.class
            if (nClass === 'root') {
              return 16
            } if (nClass === 'abstract') {
              return 12
            }
            return 8
          })

        // Other nodes animation/opacity clear
        // Clears the opacity of other nodes
        // Has a timer of ~50ms
        d3.select('#nodes')
          .selectAll('g')
          .classed('current', false)
          .classed('curChild', false)
          .transition()
          .duration(50)
          .style('opacity', 1)

        d3.select('#links')
          .selectAll('line')
          .classed('current', false)
          .classed('curChild', false)
          .transition()
          .duration(50)
          .style('opacity', 1)
          .style('stroke', 'gray')
          .style('stroke-width', 0.75)
      })
    },

    clearSVG() {
      d3.select('#d3ForceGraph').select('svg').remove()
    },
    clearNodes() {
      d3.select('#everything').remove()
    },
    // Fully refresh the graph and the store
    refreshGraph(val) {
      const root = val || this.$route.params.id
      if (this.isForce) {
        this.$store.dispatch('domainModel/getCompTreeData', root).then(() => {
          setTimeout(() => {
            this.clearSVG()
            this.sortTree()
            this.generateGraph()
          }, 500)
        })
      } else {
        this.$store.dispatch('domainModel/getCompTreeData', root).then(() => {
          this.graphKey += 1
        })
      }
    },
    // Toggle the force on/off via the `isForce` global variable
    toggleForce() {
      if (this.isForce) {
        this.isForce = false
        this.$router.push({ query: { force: 'false' } })
      } else {
        this.isForce = true
        this.$router.push({ query: { force: 'true' } })
      }
      this.refreshGraph()
    },

    goHome() {
      if (this.isForce) {
        this.$router.push({ name: 'domain_ecosystem', query: { force: 'true' } })
      } else {
        this.$router.push({ name: 'domain_ecosystem', query: { force: 'false' } })
      }
      this.refreshGraph()
    },

    // safeJSON(data) {
    //   const safeJsonStringify = require('safe-json-stringify')
    //   console.debug('Unsafe version: ', data)
    //   console.debug('Safe version: ', safeJsonStringify(data))
    //
    //   return safeJsonStringify(data)
    // },
  },
}
</script>

<style lang="scss">
/* Dark mode */
.dark-layout {
  #d3ForceGraph {
    .d3bground {
      fill: #161d31;
    }
    .d3ForceText {
      fill: wheat;
      stroke: white;
      stroke-width: 0.1;
    }
    .d3ForceLink {
      opacity: 1;
    }
  }
}

#d3ForceGraph {
  .d3bground {
    fill: whitesmoke;
  }
  .d3ForceText {
    fill: black;
    stroke: black;
    stroke-width: 0.2;
  }
  .d3ForceLink {
    stroke: gray;
    stroke-width: 0.75;
    opacity: 0.5;
  }
  /* Default for both themes*/
  .root {
    fill: orangered;
  }

  .abstract {
    fill: orange;
  }

  .node {
    fill: wheat;
  }

  /* Context menu Classes */
  .contextMenu {
    stroke: black;
    fill: #fff;
  }

  .menuEntry {
    cursor: pointer;
  }

  .menuEntry text {
    font-size: 12px;
  }
}

.entity-pane {
  border-left: 1px dotted;
   overflow-y: auto;
}
</style>
